import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useNavigate} from 'react-router-dom';
import Franchisee from "./pages/Franchisee";
import FranchiseeSpain from "./pages/FranchiseeSpain";
import Distributor from "./pages/Distributor";
import UkFranchisee from "./pages/UkFranchisee";
import TagManager from 'react-gtm-module';

function App() {
    const [redirectPath, setRedirectPath] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/') {
            const yoleURL = window.location.href = 'https://yole.com';
            setRedirectPath(yoleURL);
        }

    }, [redirectPath]);

    const tagManagerArgs = {
        gtmId: 'GTM-M3F9PNG'
    }

    TagManager.initialize(tagManagerArgs);

  return (
    <div className="boxed_wrapper">
      <Router>
        <Routes>
          {/*<Route path="/global-franchisee" element={<Franchisee />} />*/}
          {/*<Route path="/global-distributor" element={<Distributor />} />*/}
          <Route path="/spain-franchisee" element={<FranchiseeSpain />} />
          <Route path="/uk-franchisee" element={<UkFranchisee />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
