  import React, {useEffect, useRef, useState} from "react";
  import axios from '../axios';
  import { ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Loader from '../components/Loader';
  import {Link} from "react-router-dom";
  import {showToast} from "../components/ToastHelper";

  export default function FranchiseeSpain() {
    const franchiseeFormData = {
      first_name: '',
      surnames: '',
      email: '',
      phone_no: '',
      company_name: '',
      position_in_company: '',
      yole_units: '',
      community_of_interest: '',
      interested_in_location: '',
      locations_of_your_interest: '',
      does_company_do: '',
      capital_available: '',
      public_sales_experience: '',
      restoration_experience: '',
      franchising_experience: '',
      describe_your: '',
      location_already_available: '',
      yole_cafe_concept: '',
      evaluation: ''
    }

    const [formData, setFormData] = useState(franchiseeFormData);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const sectionRef = useRef();

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });

      if (type === 'checkbox') {
        setFormData({
          ...formData,
          [name]: checked,
        });
      }

      // Clear the error for the field being updated
      setErrors({
        ...errors,
        [name]: undefined,
      });
    };

    const validateForm = () => {
      const errors = {};

      if (!formData.first_name) {
        errors.first_name = 'This field is required';
      }

      if (!formData.surnames) {
        errors.surnames = 'This field is required';
      }

      const emailRegExp = /^\S+@\S+\.\S+$/;
      if (!formData.email) {
        errors.email = 'This field is required';
      } else if (!emailRegExp.test(formData.email)) {
        errors.email = 'Invalid email format';
      }

      const phoneNumberRegExp = /^\+?[0-9-() ]+$/;
      if (!formData.phone_no) {
        errors.phone_no = 'This field is required';
      } else if (!phoneNumberRegExp.test(formData.phone_no.trim())){
        errors.phone_no = "Please enter a valid phone no";
      }

      // if (!formData.company_name) {
      //   errors.company_name = 'This field is required';
      // }
      //
      // if (!formData.position_in_company) {
      //   errors.position_in_company = 'This field is required';
      // }

      if (!formData.yole_units) {
        errors.yole_units = 'This field is required';
      }

      if (!formData.community_of_interest) {
        errors.community_of_interest = 'This field is required';
      }

      if (!formData.interested_in_location) {
        errors.interested_in_location = 'This field is required';
      }

      if (!formData.locations_of_your_interest) {
        errors.locations_of_your_interest = 'This field is required';
      }

      // if (formData.main_profile_group === "Other" && !formData.others) {
      //   errors.others = 'This field is required';
      // }

      if (!formData.capital_available) {
        errors.capital_available = 'This field is required';
      }

      if (!formData.public_sales_experience) {
        errors.public_sales_experience = 'This field is required';
      }

      if (!formData.restoration_experience) {
        errors.restoration_experience = 'This field is required';
      }

      if (!formData.franchising_experience) {
        errors.franchising_experience = 'This field is required';
      }

      if (!formData.describe_your) {
        errors.describe_your = 'This field is required';
      }

      if (!formData.location_already_available) {
        errors.location_already_available = 'This field is required';
      }

      if (!formData.yole_cafe_concept) {
        errors.yole_cafe_concept = 'This field is required';
      }

      if (!formData.evaluation) {
        errors.evaluation = 'This field is required';
      }

      // if (!formData.agree_privacy_policy) {
      //   errors.agree_privacy_policy = 'You must agree to the privacy policy';
      // }

      // Scroll to the first error field if there are errors
      /*if (Object.keys(errors).length > 0) {
        const firstErrorField = document.querySelector('.text-danger');

        if (firstErrorField) {
          // Scroll to the element causing the error
          firstErrorField.scrollIntoView({ behavior: 'smooth' });
        }
      }*/

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      if (validateForm()) {
        setLoading(true);
        axios.post('/spain-franchisee', formData)
            .then((response) => {
              showToast(response.data.message, 'success');
              setFormData(franchiseeFormData);
              setErrors({});
            })
            .catch((error) => {
              // console.error('Form submission error', error.response.data.errors);
              if (error.response && error.response.status === 422) {

                const validationErrors = error.response.data.errors;
                Object.keys(validationErrors).forEach((field) => {
                  showToast(validationErrors[field][0], 'error');
                });
              } else {
                console.error('Error updating data:', error);
              }
            })
            .finally(() => {
              setLoading(false);
            });
      } else {
        showToast('Please check and filled all the fields', 'error');
      }
    };

    const scrollToSection = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
        <>
          <section className="banner-section style-three">
            <picture>
              <img src="/assets/images/go-img/spanish-franchisee.webp" className="mobile-hide" alt="Franchisee" />
              <img src="/assets/images/go-img-mbl/spanish-franchisee.webp" className="mobile-show" alt="Franchisee" />
            </picture>
          </section>

          <section className="bg-secondary pt-60 pb-60 franchisee-opportunity">
            <div className="auto-container">
              <div className="sec-title centred mb-20">
                <h2 className="text-uppercase heading-text">La oportunidad de franquicia con Yolé</h2>
                <p className="text-white sub-title mt-0 text-uppercase">Innovando en la industria de helados y yogur congelado</p>
                <p className="normal-paragraph">En Yolé, estamos en una misión para revolucionar el panorama de los helados y el yogur congelado. Estamos buscando socios dedicados que compartan nuestra visión y estén ansiosos por unirse a nosotros en la expansión de nuestra marca en diversas regiones. Si estás interesado en convertirte en un valioso socio a largo plazo con Yolé, te invitamos a completar el formulario a continuación.</p>
                <p className="normal-paragraph">Nuestro equipo se pondrá en contacto contigo rápidamente y proporcionará detalles sobre nuestras oportunidades de franquicia, incluyendo inversiones iniciales y términos generales.</p>
              </div>
            </div>
          </section>

          <section className="contact-section bg-primary section-padding pt-0 pb-0" ref={sectionRef} style={{height: '700px'}}>
            <div className="auto-container">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 form-column">
                  <div className="form-inner">
                    <form className="default-form" onSubmit={handleSubmit}>
                      <div className="sec-title centred mb-2">
                        <h2 className="text-white">Hello There</h2>
                      </div>
                      <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Nombre <span className="text-danger">*</span></label>
                          <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                          {errors.first_name && <p className="text-danger error">{errors.first_name}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Apellidos <span className="text-danger">*</span></label>
                          <input type="text" name="surnames" value={formData.surnames} onChange={handleChange} />
                          {errors.surnames && <p className="text-danger error">{errors.surnames}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Email <span className="text-danger">*</span></label>
                          <input type="email" name="email" value={formData.email} onChange={handleChange} />
                          {errors.email && <p className="text-danger error">{errors.email}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Teléfono <span className="text-danger">*</span></label>
                          <input type="text" name="phone_no" value={formData.phone_no} onChange={handleChange} />
                          {errors.phone_no && <p className="text-danger error">{errors.phone_no}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Nombre de empresa y website (en caso de ser empresa)</label>
                          <input type="text" name="company_name" value={formData.company_name} onChange={handleChange} />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Posición en la empresa (dueño, director…)</label>
                          <input type="text" name="position_in_company" value={formData.position_in_company} onChange={handleChange} />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Cuántas unidades Yolé te gustaría abrir?<span className="text-danger">*</span></label>
                          <select name="yole_units" className="form-control" value={formData.yole_units} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Sólo 1 por ahora">Sólo 1 por ahora</option>
                            <option value="2 tiendas Yolé">2 tiendas Yolé</option>
                            <option value="3 tiendas Yolé">3 tiendas Yolé</option>
                            <option value="4 tiendas Yolé">4 tiendas Yolé</option>
                            <option value="Me gustaría abrir 5 o más con exclusividad en mi área geográfica.">Me gustaría abrir 5 o más con exclusividad en mi área geográfica.</option>
                          </select>
                          {errors.yole_units && <p className="text-danger error">{errors.yole_units}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Comunidad Autónoma de tu interés<span className="text-danger">*</span></label>
                          <select name="community_of_interest" className="form-control" value={formData.community_of_interest} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Más de una Comunidad Autónoma">Más de una Comunidad Autónoma</option>
                            <option value="Toda España">Toda España</option>
                            <option value="Andalucía">Andalucía</option>
                            <option value="Aragón">Aragón</option>
                            <option value="Asturias">Asturias</option>
                            <option value="Cantabria">Cantabria</option>
                            <option value="Castilla-La Mancha">Castilla-La Mancha</option>
                            <option value="Castilla y León">Castilla y León</option>
                            <option value="Cataluña">Cataluña</option>
                            <option value="Extremadura">Extremadura</option>
                            <option value="Galicia">Galicia</option>
                            <option value="Islas Baleares">Islas Baleares</option>
                            <option value="Islas Canarias">Islas Canarias</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="Madrid">Madrid</option>
                            <option value="Murcia">Murcia</option>
                            <option value="Navarra">Navarra</option>
                            <option value="País Vasco">País Vasco</option>
                            <option value="Valencia">Valencia</option>
                            <option value="Ceuta">Ceuta</option>
                            <option value="Melilla">Melilla</option>
                          </select>
                          {errors.community_of_interest && <p className="text-danger error">{errors.community_of_interest}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Interesado en una sola localidad o en varias localidades?<span className="text-danger">*</span></label>
                          <select name="interested_in_location" className="form-control" value={formData.interested_in_location} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Sólo en una localidad.">Sólo en una localidad.</option>
                            <option value="Interesado en abrir en varias localidades">Interesado en abrir en varias localidades</option>
                          </select>
                          {errors.interested_in_location && <p className="text-danger error">{errors.interested_in_location}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Localidad o localidades de tu interés</label>
                          <input type="text" name="locations_of_your_interest" value={formData.locations_of_your_interest} onChange={handleChange} />
                          {errors.locations_of_your_interest && <p className="text-danger error">{errors.locations_of_your_interest}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>En caso de ser empresa, a qué se dedica la empresa</label>
                          <input type="text" name="does_company_do" value={formData.does_company_do} onChange={handleChange} />
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Cuál es el capital disponible para invertir en una tienda Yolé?<span className="text-danger">*</span></label>
                          <select name="capital_available" className="form-control" value={formData.capital_available} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Hasta 50,000 euros">Hasta 50,000 euros</option>
                            <option value="Hasta 100,000 euros">Hasta 100,000 euros</option>
                            <option value="Hasta 150,000 euros">Hasta 150,000 euros</option>
                            <option value="Hasta 200,000 euros">Hasta 200,000 euros</option>
                            <option value="Hasta 300,000 euros">Hasta 300,000 euros</option>
                            <option value="Más de 300,000 euros">Más de 300,000 euros</option>
                          </select>
                          {errors.capital_available && <p className="text-danger error">{errors.capital_available}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Tienes tú o tu empresa experiencia previa en ventas al público?<span className="text-danger">*</span></label>
                          <select name="public_sales_experience" className="form-control" value={formData.public_sales_experience} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.public_sales_experience && <p className="text-danger error">{errors.public_sales_experience}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Tienes tú o tu empresa experiencia previa en restauración?<span className="text-danger">*</span></label>
                          <select name="restoration_experience" className="form-control" value={formData.restoration_experience} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.restoration_experience && <p className="text-danger error">{errors.restoration_experience}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Tienes tú o tu empresa experiencia previa en franquicias?<span className="text-danger">*</span></label>
                          <select name="franchising_experience" className="form-control" value={formData.franchising_experience} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                          {errors.franchising_experience && <p className="text-danger error">{errors.franchising_experience}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Describe a qué te dedicas actualmente o a qué se dedica la empresa. <span className="text-danger">*</span></label>
                          <textarea name="describe_your" value={formData.describe_your} onChange={handleChange} />
                          {errors.describe_your && <p className="text-danger error">{errors.describe_your}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Tienes local ya disponible?<span className="text-danger">*</span></label>
                          <select name="location_already_available" className="form-control" value={formData.location_already_available} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Sí">Sí</option>
                            <option value="Sí varios potencialmente disponible">Sí varios potencialmente disponible</option>
                            <option value="Sí, mi actual local que quiero reconvertir a un Yolé">Sí, mi actual local que quiero reconvertir a un Yolé</option>
                            <option value="No">No</option>
                            <option value="No, pero estoy en búsqueda activa">No, pero estoy en búsqueda activa</option>
                          </select>
                          {errors.location_already_available && <p className="text-danger error">{errors.location_already_available}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Estás interesado en Yolé o en el concepto Yolé Café?<span className="text-danger">*</span></label>
                          <select name="yole_cafe_concept" className="form-control" value={formData.yole_cafe_concept} onChange={handleChange}>
                            <option value="">Please Choose Option</option>
                            <option value="Sólo en heladerías Yolé">Sólo en heladerías Yolé</option>
                            <option value="Sólo en cafeterías Yolé Café">Sólo en cafeterías Yolé Café</option>
                            <option value="Ambos">Ambos</option>
                            <option value="Lo decidiré más adelante">Lo decidiré más adelante</option>
                          </select>
                          {errors.yole_cafe_concept && <p className="text-danger error">{errors.yole_cafe_concept}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <label>Algo más que quieras contarnos para la evaluación de tu candidatura? <span className="text-danger">*</span></label>
                          <textarea name="evaluation" value={formData.evaluation} onChange={handleChange} />
                          {errors.evaluation && <p className="text-danger error">{errors.evaluation}</p>}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn">
                          <button className="theme-btn-one bg-primary w-100" type="submit" name="submit-form">Submit Now</button>
                        </div>
                      </div>
                    </form>

                    {loading && <Loader />}
                    <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding pb-0">
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column sp-col">
                    <h2 className="text-uppercase heading-text heading-text-secondary">DESCUBRE LA EXPERIENCIA DE YOLÉ</h2>
                    <p className="text-black sub-title mt-0 text-uppercase font-weight-bold">El menú de Yolé se adapta para satisfacer tu mercado local</p>
                    <div className="mr-10 column-text-block" style={{paddingRight: '94px'}}>
                      <p className="mb-10 mt-3">El menú de Yolé está diseñado para adaptarse a tus preferencias únicas y los gustos locales. Como pioneros en Delicias Cero Lácteos, Cero Azúcar Añadido y 100% Veganas, siempre estamos innovando en nuestro laboratorio de I+D para presentar sabores que se alineen con las últimas tendencias. Nuestro compromiso con la excelencia garantiza que cada cucharada brinde un sabor excepcional sin comprometer la calidad. Además, atendemos a diversas necesidades dietéticas sin sacrificar sabor ni calidad.</p>
                      <p>Únete a nosotros para disfrutar de un mundo de indulgencia saludable, donde cada cucharada celebra el sabor y el bienestar.</p>
                    </div>
                    <div className="btn-box mt-25">
                      <button className="bg-primary bg-secondary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Order</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="yole-exp section-padding pb-0">
            <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 image-column d-flex justify-content-end">
                  <div className="image-box">
                    <figure className="image">
                      <img src="/assets/images/go-img/yole_exp.webp" className="mobile-hide" alt="YOLÉ Experience" />
                      <img src="/assets/images/go-img-mbl/yole-exp.png" className="mobile-show" alt="YOLÉ Experience" />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="pt-30 pb-30 bg-secondary-02 centred">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="heading-text-secondary text-uppercase">Sistema modular de quioskos ecológicos</h3>
              </div>
            </div>
          </section>


          <section className="service-section section-padding remove-before bg-primary">
            <div className="auto-container">
              <div className="yole-boosting">
                <p className="text-left text-white">En Yolé, tenemos la misión de revolucionar el panorama de los helados y el yogur helado. Buscamos socios dedicados que compartan nuestra visión y estén ansiosos por unirse a nosotros para expandir nuestra marca en varias regiones. Si está interesado en convertirse en un valioso socio a largo plazo de Yolé, lo invitamos a completar el siguiente formulario.</p>
                <p className="text-left text-white mt-3">Nuestro equipo se comunicará con usted de inmediato y le brindará detalles sobre nuestras oportunidades de franquicia, incluidas las inversiones iniciales y los términos generales.</p>
              </div>
              <div className="row clearfix mt-60">
                <div className="col-lg-4">
                  <div className="kiosks-box">
                    <figure>
                      <img src="/assets/images/go-img/why-smart.svg" alt="Why Smart" />
                    </figure>
                    <h4>¿POR QUÉ SMART?</h4>
                    <p>Las pantallas digitales y los LEDs son la mejor manera de mostrar nuestros menús de productos y sus características. Una manera dinámica e inteligente de, además, mostras los valores de la marca y los mensajes promocionales. Todos los impresionantes videos son realizados internamente por nuestros equipos creativos que los pone a disposición gratuitamente a nuestros franquiciados.</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kiosks-box">
                    <figure>
                      <img src="/assets/images/go-img/why-modular.svg" alt="Why Modular" />
                    </figure>
                    <h4>¿POR QUÉ MODULAR?</h4>
                    <p>Tenemos uno sistema único de diseño ad-hoc de quioscos que se adaptan a cualquier disponibilidad de espacio de manera muy económica. Estos quioscos son fácilmente montable y además, movibles, para el caso de que quieras llevarlo a una nueva mejor localización sin tener que perder la inversión inicial.</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="kiosks-box">
                    <figure>
                      <img src="/assets/images/go-img/why-eco.svg" alt="Why Eco" />
                    </figure>
                    <h4>¿POR QUÉ ECO-FRIENDLY?</h4>
                    <p>Yolé está altamente comprometido con el medio ambiente. Por esta razón los materiales que utilizamos en nuestros quioscos y tiendas provienen de maderas que están certificadas para que el daño medioambiental sea nulo. Las maderas certificadas garantizan que los árboles talados han sido ampliamente compensados con la siembra de más árboles.</p>
                  </div>
                </div>
              </div>

              <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 centred">
                  <div className="btn-box mt-50">
                    <button className="bg-white-color theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Order</button>
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="promotion-section y-app-operation-section section-padding bg-secondary remove-before">
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column order-2">
                    <div className="mr-10 y-app-operation">
                      <h2 className="heading-text text-white" style={{ fontSize: "1.8rem" }}>EL SISTEMA DE OPERACIONES Y-APP</h2>
                      <p className="text-white mb-10 text-uppercase font-weight-bold">Una plataforma de <span className="heading-text">franquicia</span> para controlar y<br /> supervisar todas sus tiendas.</p>
                      <p className="mb-20 text-para">Estamos cambiando la forma de controlar, auditar, comunicar y formar a tu personal en tienda. Nuestro software monitoreará todos los procesos diarios seguidos por su personal. Podrá monitorear y controlar sin problemas sus múltiples tiendas en tiempo real.</p>
                      <p className="mb-20 text-para">Mejorar el servicio directo a los clientes, estandarizar el servicio en todo el mundo y hacer que el personal de Yolé sea más eficientes le ayudará a generar clientes leales a Yolé.</p>
                      <p className="text-para mb-30">
                        Módulo de gestión operativa en tienda para empleados. <br />
                        Módulo de gestión de incidencias de cada tienda. <br />
                        Módulo de noticias, promociones e instrucciones. <br />
                        Módulo de formación y educación (con vídeos y test). <br />
                        Módulo de comunicación de franquicia entre empleados y gerentes.
                      </p>
                      <div className="btn-box mt-30"><button className="bg-primary theme-btn-one" onClick={() => scrollToSection(sectionRef)}>Apply Now</button></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 image-column order-1">
                    <div className="image-box ml-10">
                      <figure className="image">
                        <img src="/assets/images/go-img/y-app.webp" className="" alt="EL SISTEMA DE OPERACIONES Y-APP" />
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="promotion-section section-padding bg-primary y-cloud-section remove-before">
            <div className="auto-container">
              <div className="inner-box wow slideInLeft animated animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                <div className="row clearfix">
                  <div className="col-lg-6 col-md-12 col-sm-12 content-column d-flex align-items-center">
                    <div className="mr-10 y-cloud">
                      <h2 className="text-white">EL SISTEMA Y-CLOUD</h2>
                      <p className="text-white text-uppercase heading-para">Toda la documentación Yolé en<br/> una nube para todos los <span className="heading-text">franquiciados.</span></p>
                      <p className="text-white text-para">Una nueva plataforma fácil de usar facilita la obtención de cualquier herramienta y documentación en cualquier momento. Hemos cambiado la forma de acceder y compartir automáticamente todo el know-how (manuales, vídeos de formación, herramientas de marketing, procedimientos operativos…)</p>
                    </div>
                  </div>
                  <div className="col-lg-5 offset-lg-1 col-md-12 col-sm-12 image-column d-flex align-items-center">
                    <div className="image-box">
                      <figure className="image"><img src="/assets/images/go-img/y-cloud.webp" alt="EL SISTEMA Y-CLOUD" /></figure>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

          <footer className="main-footer centred">
            <div className="footer-bottom">
              <div className="auto-container clearfix">
                <p className="text-white">Checkout our latest news at <Link to="https://www.yole.com/whats-new" target="_blank" rel="noopener noreferrer">https://www.yole.com/whats-new</Link></p>
              </div>
            </div>
          </footer>

          <button className="scroll-top scroll-to-target" data-target="html">
            <span className="icon-Arrow-Up"></span>
          </button>
        </>
      );
  }